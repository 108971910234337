<template>
  <div :key="currentLang" class="sp-app-container default-layout">
    <div>
      <HeaderSp />
      <ClientOnly>
        <BannersDevBanner v-if="onStaging || onQA" :on-staging="onStaging" />
        <NavbarLeft class="hide-sm" />
      </ClientOnly>
      <div>
        <transition name="fade">
          <div v-show="modals > 0" class="cart-modals">
            <CartConfirmation @modal-show="modals += 1" @modal-hide="modals -= 1" />
          </div>
        </transition>
        <Snackbar />
      </div>
      <div class="layout-content" data-test-id="layout-content">
        <slot />
        <ClientOnly>
          <LocationSelectionModal />
        </ClientOnly>
      </div>
    </div>
    <FooterSp class="footer-class-for-lazy" />
    <ConfirmationModal modal-name="confirmationModalAddToCart" element-test="accreditation">
      <template #title>
        <div class="u-d-f u-c-grapefruit" data-test-id="accreditation-modal-title">
          <span class="icon-problem-grapefruit" data-test-id="accreditation-modal-icon" />
          <b>{{ $t('accreditation.title') }}</b>
        </div>
      </template>
      <template #content>
        <div data-test-id="accreditation-modal-content">
          <strong data-test-id="accreditation-modal-text" v-html="$t('accreditation.text')" />
        </div>
      </template>
      <template #confirm-button>
        <MainButton
          :label="$t('accreditation.modalConfirmButton')"
          tag="button"
          data-test-id="accreditation-modal-confirm-button"
          @click="addToCart(true, stock, quantity, fastAddToCart)"
        />
      </template>
    </ConfirmationModal>
    <ZendeskButton v-if="$route.path !== '/stock'" />
    <RightSidebarModal :active="rightSidebarActive" @close="closeRightSidebar">
      <template #content>
        <LazyModalsZipCode v-if="rightSidebarActive" @close="closeRightSidebar" />
      </template>
    </RightSidebarModal>
  </div>
</template>

<script setup>
import { useClubStore } from '@/stores/club'
import { useUtmStore } from '@/stores/utm'
import { useCategoriesStore } from '@/stores/categories'
import { useUserStore } from '@/stores/user'
import { useEmitterStore } from '@/stores/emitter'

import { useAxeptioEvent } from '@/composables/Analytics/useAxeptio'
import { useCountries } from '@/composables/i18n/useCountries'
import { useTheme } from '@/composables/useTheme'
import { useAddToCart } from '@/composables/useAddToCart'

import HeaderSp from '@/components/Header/Header'
import FooterSp from '@/components/Footer/Footer.vue'
import CartConfirmation from '@/components/Modals/CartConfirmation'
import NavbarLeft from '@/components/NavbarLeft'
import Snackbar from '@/components/Snackbar/Snackbar'
import LocationSelectionModal from '@/components/I18n/LocationSelectionModal'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import ZendeskButton from '@/components/Buttons/ZendeskButton/ZendeskButton'
import RightSidebarModal from '@/components/Modals/SidebarModal/RightSidebarModal'

const emitter = useEmitterStore()
const userStore = useUserStore()
const { analytics, events } = useAnalytics()
const { tld, locale, country } = useCurrentLocale()

const route = useRoute()

const { defaultHead, defaultSeo } = useDefaultMetaData()
const routeData = route.meta.metaData
useHead(routeData?.head ?? defaultHead())
useSeoMeta(routeData?.seo ?? defaultSeo())
// TODO add schema

const modals = ref(0)

const clubStore = useClubStore()
const config = useRuntimeConfig()
const onStaging = computed(() => config.public.ENV_NAME === 'STAGING')
const onQA = computed(() => config.public.ENV_NAME === 'QA')

const currentLang = locale

const rightSidebarActive = ref(false)
const closeRightSidebar = () => {
  rightSidebarActive.value = false
}
onBeforeUnmount(() => {
  emitter.off('addToCartAction')
})

const { stock, quantity, fastAddToCart, addToCart, isUpsell, originForTracking } = useAddToCart()
onBeforeMount(() => {
  analytics.sendEvent(events.LOGIN_STATUS, userStore)

  const { countries } = useCountries()
  const country = countries.find(x => x.iso.toUpperCase() === tld.toUpperCase())

  if (route.query?.source_host) {
    const selectedHost = useCookie('x-selectedHost', {
      maxAge: 60 * 60 * 24 * 365,
      ...(country ? { domain: `.${country.host}` } : {}),
    })
    selectedHost.value = country.host
  }
  emitter.on('addToCartAction', data => {
    stock.value = data.stock
    quantity.value = data.quantity
    fastAddToCart.value = data.fastAddToCart
    isUpsell.value = data.isUpsell
    originForTracking.value = data.originForTracking
    addToCart(data.accepted)
  })

  emitter.on('openRightSidebarModalZipCode', () => {
    rightSidebarActive.value = true
  })
  emitter.on('closeRightSidebarModalZipCode', () => {
    rightSidebarActive.value = false
  })
})

const cookieLang = useCookie('x-lang', {
  maxAge: 60 * 60 * 24 * 365,
  domain: `.${country?.host}`,
})

const { switchLocale } = usei18nMx()
const { $i18n } = useNuxtApp()
if (route.query?.lang && (route.query?.lang !== cookieLang.value || cookieLang.value !== locale)) {
  await switchLocale(Array.isArray(route.query.lang) ? route.query.lang[0] : route.query.lang)
} else if (country?.availableLanguages?.includes(locale) && $i18n.locale.value !== locale) {
  await switchLocale(locale)
} else if (country?.availableLanguages?.length && $i18n.locale.value !== locale) {
  await switchLocale(country.availableLanguages[0])
} else {
  const categoriesStore = useCategoriesStore()
  await categoriesStore.getAvailableCategories()
}

onMounted(async () => {
  if (document?.referrer && !document?.referrer?.includes(window?.location?.host)) {
    localStorage.removeItem('originForTracking')
  }
  if (route.query?.awc) {
    useAxeptioEvent(() => {
      // AWC = Awin cookie, only create it if Axeptio accepted
      const awcCookie = useCookie('awc', {
        maxAge: 60 * 60 * 24 * 30,
        secure: true,
      })
      awcCookie.value = route.query.awc
    })
  }

  if (clubStore.isClub) {
    const colors = clubStore.clubConfig?.theme?.colors
    if (colors) {
      const { setTheme } = useTheme()
      setTheme({ theme: { colors }, favicon: '/favicon.ico' })
    }
  }

  const utms = getUtmTags()
  const utmStore = useUtmStore()
  utms.forEach(utm => {
    utmStore.add(utm)
  })

  nextTick(() => {
    if (!window.gtmLoaded) {
      window.gtmLoaded = true
      const gtm = useGtm()
      gtm.enable(true) // Enable GTM dynamically
    }
  })
})

const isAuthenticated = computed(() => {
  return userStore.isAuthenticated
})

watch(
  () => isAuthenticated.value,
  v => {
    if (v && import.meta.client) {
      const customStyleTag = document.createElement('style')
      customStyleTag.setAttribute('data-hid', 'custom-styles')
      customStyleTag.innerHTML = '#PopupSignupForm_0 {display: none;}'
      document.body.appendChild(customStyleTag)
    }
  }
)

const getUtmTags = () => {
  const utms = []
  const queryParams = Object.keys(route.query)
  const utmParams = queryParams.filter(key => key.startsWith('utm_'))

  utmParams.forEach(param => {
    utms.push(`${param}:${route.query[param]}`)
  })

  return utms
}
</script>

<style lang="scss">
@import '@/assets/scss/partials/tpl/error-page';

.default-layout {
  margin: 0 auto !important;
  max-width: $content-max-width;
}

.topbar {
  position: relative;
  top: 0;
  margin-left: $navbar-left-width;
  display: flex;
  align-items: flex-start;
  z-index: 12;

  @include mq($mq-md) {
    display: inherit;
    margin-left: 0;
  }

  &:has(.search-autocomplete-wrapper) {
    z-index: 13;
  }
}

.layout-content {
  position: relative;
  width: calc(100% - 100px - $spacing-md);
  left: 100px;

  @include mq($mq-sm) {
    width: 100%;
    left: 0;
    padding-right: 0;
  }
}

.cart-modals {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  pointer-events: none;
  overflow: visible;
  padding-top: $header-padding-v;

  @include mq($mq-sm) {
    padding-top: $header-padding-v-sm;
  }

  * {
    pointer-events: auto;
  }

  .cart-modal-wrapper {
    width: 100%;
    max-width: 345px;
  }

  .cart-modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
  }

  .cart-modal {
    position: relative;
    padding: 15px;
    background-color: var(--white);
    border-radius: $border-radius-boxes;
    z-index: 2;
    margin: 0 0 20px 0;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);

    .cart-modal-btn-wrapper {
      text-align: right;

      .cart-modal-close {
        text-align: right;
        display: inline-block;
        height: 28px;
        width: 28px;
        background-image: url('@/assets/img/svg/icons/close/ico-close-popup.svg');
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        transition: transform var(--transition-duration) var(--transition-timing-function);

        &:hover {
          transform: rotate(-90deg);
        }
      }
    }

    .cart-modal-content {
      .btn {
        margin-top: 15px;
      }
    }
  }
}
</style>
